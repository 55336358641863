/**
 * Creates a file link object to be used in UserUploadDialog
 * @param transit
 * @return {{}}
 */
function transitFileLink (transit) {
  let search = {}
  if (transit) search.transits = transit.id
  if (transit && transit.manifests) search.inventory_manifests = transit.manifests.map(m => m.id)
  if (transit && transit.check_ins) search.check_ins = transit.check_ins.map(m => m.id)
  return search
}

/**
 * Creates a file link object to be used in UserUploadDialog
 * @param manifest
 * @param transit
 * @return {{}}
 */
function manifestTransitFileLinks (manifest, transit) {
  let search = {}
  if (manifest) search.inventory_manifests = manifest.id
  if (transit) search.transits = transit.id
  return search
}

/**
 * Creates a file link object to be used in UserUploadDialog
 * @param checkIn
 * @return {{}}
 */
function checkInFileLinks (checkIn) {
  let search = {}
  if (checkIn) search.check_ins = checkIn.id
  return search
}

/**
 * Creates a file link object to be used with brokerage terms
 * @param brokerageTerms
 * @return {{}}
 */
function brokerageTermsFileLinks (brokerageTerms) {
  let search = {}
  if (brokerageTerms) search.brokerage_terms = brokerageTerms.id
  return search
}

/**
 * Creates a file link object to be used with smelters
 * @param smelter
 * @return {{}}
 */
function smelterFileLinks (smelter) {
  let search = {}
  if (smelter) search.smelters = smelter.id
  return search
}

function yardFileLink (yard) {
  return {
    yards: yard.id
  }
}

function lotFileLink (lot) {
  return {
    lots: lot.id
  }
}

function shipmentSummaryFileLink (ss) {
  return {
    'shipment_summaries': ss.id
  }
}

export default {
  brokerageTermsFileLinks,
  checkInFileLinks,
  manifestTransitFileLinks,
  transitFileLink,
  lotFileLink,
  smelterFileLinks,
  yardFileLink,
  shipmentSummaryFileLink
}
