<!--
Allows for file upload based on linkFiles
linkFiles = { inventory_manifests: id|[id], transits: id|[id] }
the params will be used to do a OR based search for related images.
Images upload will be attached to all of the files
@emit('newFilesAdded') when new files have been added and dialog is closed
-->
<template>
  <div>
    <slot
      name="activator"
      :on="() => dialog = !dialog"
    >
      <v-btn
        color="primary"
        small
        @click="dialog = !dialog"
      >
        Attach/View Files
      </v-btn>
    </slot>
    <edit-dialog
      v-model="dialog"
      headline-text="Attach Files"
      :show-decline="false"
      accept-text="Done"
    >
      <help-dialog
        headline-text="File Link Details"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            text
            small
            @click="on"
          >
            See file Linking Details
          </v-btn>
        </template>
        <p>
          When files are created they will be linked to documents listed below.
          Additionally files linked to any of the documents below will be show in this dialog.
        </p>
        <v-list>
          <v-list-item
            v-for="(files, index) in fileLinks"
            :key="index"
          >
            <v-list-item-title>
              {{ index.toUpperCase().replace('_', ' ') }}: {{ files }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </help-dialog>
      <v-skeleton-loader
        v-if="fetching"
        type="list-item-avatar-three-line@3"
      />
      <p
        class="text-center"
        v-else-if="attachedFiles.length === 0"
      >
        <em>
          No files to show
        </em>
      </p>
      <v-list
        v-else
        dense
      >
        <v-list-item
          v-for="file in attachedFiles"
          :key="file.id"
          @click="openFile(file)"
        >
          <v-list-item-avatar
            tile
            color="primary"
            height="80"
            width="120"
            class="text-center"
          >
            <v-img
              v-if="previewTypes.includes(file.content_type)"
              :src="file.upload"
            />
            <v-icon
              v-else
              color="white"
            >
              mdi-cloud-download-outline
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ file.filename }}
            </v-list-item-title>
            <p class="text-left">
              <small>
                uploaded by {{ file.created_by__username }} on {{ file.created_at | formatDate }}
              </small>
            </p>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              fab
              text
              small
              @click.stop.prevent="deleteFile(file)"
            >
              <v-icon>
                mdi-delete-forever-outline
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-card
        class="grey"
        :class="{ 'lighten-3': !$vuetify.theme.dark, 'darken-4': $vuetify.theme.dark }"
        outlined
        v-cloak
        @drop.prevent="addDropFile"
        @dragover.prevent
      >
        <v-card-text class="text-center">
          Files can be drag an dropped here.
          <v-icon>
            mdi-file-upload-outline
          </v-icon>
          <v-file-input
            v-model="files"
            counter
            multiple
            show-size
            truncate-length="15"
            hint="file(s) will be automatically linked currently related items"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="files.length === 0"
            :loading="uploading"
            small
            color="primary"
            @click="uploadFiles"
          >
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </edit-dialog>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'UserUploadDialog',
  props: {
    /**
     * Assignments should contain { searchName: Array<int>  }
     * These will be used to lookup files using the key and ids
     */
    fileLinks: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    newFiles: false,
    fetching: false,
    dialog: false,
    uploading: false,
    files: [],
    attachedFiles: [],
    previewTypes: [
      '.jpg',
      '.jpeg',
      '.gif',
      '.png'
    ],
    // used by UserUploadList components to ensure they only update when their own files list is updated
    fetchParamsString: null
  }),
  watch: {
    dialog (opened) {
      if (opened) {
        this.fetchAttachedFiles()
        this.newFiles = false
      } else {
        if (this.newFiles) {
          this.$emit('newFilesAdded', true)
        }
      }
    }
  },
  methods: {
    addDropFile (e) {
      this.files = Array.from(e.dataTransfer.files)
    },
    /**
     * Triggers a open file/download file
     */
    openFile (file) {
      if (!file.upload) {
        this.$store.commit('setSnackbarWarning', 'No uploaded resource available')
        return
      }
      window.open(file.upload, '_blank')
    },
    deleteFile (file) {
      if (confirm('Are you sure you want to delete the attached file?')) {
        axios.delete(file.url).then(() => {
          this.fetchAttachedFiles()
        })
      }
    },
    /**
     * Starts the file upload loop then clears the files
     */
    uploadFiles () {
      if (!this.files) {
        this.$store.commit('setSnackbarError', 'No files to upload')
        return
      }
      this.uploading = true
      Promise.all(this.files.map(this.uploadFile)).finally(() => {
        setTimeout(() => {
          this.files = []
          this.fetchAttachedFiles()
          this.uploading = false
          this.newFiles = true
        }, 3000)
      })
    },
    /**
     * Adds file link params to the params object
     */
    getCreateParams (params) {
      for (const key in this.fileLinks) {
        const v = this.fileLinks[key]
        params[key] = Array.isArray(v) ? v : [v]
      }
      return params
    },
    async deleteUserUpload (url) {
      try {
        await axios.delete(url)
      } catch {
        this.$store.commit('setSnackbarError', `Failed to delete UserUpload object at ${url}`)
      }
    },
    /**
     * Posts a new userUpload file location using the current filename
     */
    async createFile (file) {
      let p1 = { filename: file.name }
      const r1 = await axios.post('/api/user_uploads/', this.getCreateParams(p1))
      if (r1.status !== 201) {
        this.$store.commit('setSnackbarError', `Unable to create upload destination for ${file.name}`)
        return false
      }
      return r1.data
    },
    /**
     * Retrieves the upload url data for a userUpload file
     */
    async getUploadUrl (file, userUpload) {
      if (!userUpload) return false
      try {
        const r2 = await axios.get(userUpload.url + 'upload_url/')
        return r2.data
      } catch (err) {
        await this.deleteUserUpload(userUpload.url)
        this.$store.commit('setSnackbarError', err.response.data.message)
      }
    },
    /**
     * Uploads the userUpload file to AWS
     */
    async awsPutFile (file, fileUpload) {
      if (!fileUpload) return false
      const url = fileUpload.upload_url
      const contentType = fileUpload.content_type

      // image upload with headers
      const putResponse = await fetch(url, {
        method: 'PUT',
        headers: {
          'Access-Control-Allow-Methods': '*',
          'Access-Control-Allow-Origin': '*',
          'x-amz-acl': 'private',
          'Content-Type': contentType
        },
        body: file
      })

      if (putResponse.status !== 200) {
        this.$store.commit('setSnackbarError', 'Unable to upload file', { root: true })
      }
    },
    /**
     * First Provision a new entry
     * Then get a UploadURL
     * Patch that upload url
     * Finally update the entry with the new url
     * @param file
     * @return {Promise<void>}
     */
    async uploadFile (file) {
      const userUpload = await this.createFile(file)
      const tfUpload = await this.getUploadUrl(file, userUpload)
      this.awsPutFile(file, tfUpload)
    },
    /**
     * Formats the fetch params to feed to UserUpload api
     */
    getFetchParams () {
      let params = { page_size: 0 }
      for (const key in this.fileLinks) {
        const v = this.fileLinks[key]
        // wraps variable in array if one already
        params[key + '__in'] = Array.isArray(v) ? v.join(',') : v
      }
      this.fetchParamsString = JSON.stringify(params)
      return params
    },
    /**
     * Fetches attached files so we have file links
     * @return {Promise<AxiosResponse<any>>}
     */
    fetchAttachedFiles () {
      this.fetching = true
      return axios.get('/api/user_uploads/', { params: this.getFetchParams() }).then(r => {
        if (r.status === 200) {
          this.attachedFiles = r.data
        }
      }).finally(() => {
        this.fetching = false
        // Use root emit so sibling UserUploadList components can listen for upload events
        this.$root.$emit('newFilesList', this.fetchParamsString)
      })
    }
  }
}
</script>
